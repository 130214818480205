import { put, call, take, fork, cancel } from "redux-saga/effects";
import { takeEvery } from 'redux-saga/effects';
import { CommonActionType } from "../reducers/Common";
import {store} from '../store/configureStore';
import request from "../utils/request";
import CONFIG from '../env'
import { DashboardReturnType } from "../components/Types";
import { handleAccountDashboardData } from "../actions/CommonActions";

export function* doFetchAccountDashboard(action:{type:string,payload:string}){
  let account = action.payload
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let requestURL = CONFIG.DSE_API_URL + "dashboard?account="+account
  let ret:DashboardReturnType = yield call(request, requestURL, {method:'GET',headers:headers});
  let data = ret.data
  /**
   * 用户帐户数据统计，ret只包括 join/redeem. holding及equity需要根据钱包余额自己计算
   * equity = $balanace * dse.index
   * earning = $equity + $redeem - $join
   *  */ 
  store.dispatch(handleAccountDashboardData(data))
}

export function* watchCommon(){
  yield takeEvery(CommonActionType.FETCH_ACCOUNT_DASHBOARD, doFetchAccountDashboard);
}