
import styles from './Loading.module.css'
import { Popover, Alert, Spin, notification, Modal } from 'antd'
import {
  QuestionCircleOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons'

const Loading = ({color='var(--green-200)',fontSize=36}:{color?:string,fontSize?:number})=>{

  return(
    <div className={styles.loading} style={{color:`${color}`, fontSize:`${fontSize}`}}>
      <Loading3QuartersOutlined spin/>
    </div>
  )
}

export default Loading;