import { Component } from 'react'
import CONFIG from '../env/index'
import { store } from '../store/configureStore'
import {
  handleAssetPrice,
  handleAssetPriceMap,
  handleDashboardData,
} from '../actions/CommonActions'

export default class ApiService extends Component {
  interval: boolean
  intervalAsyncID: NodeJS.Timer | undefined

  constructor(props: { interval: boolean }) {
    super(props)
    this.interval = props.interval
  }

  dataAsync() {
    if (!this.interval) {
      return
    }
    this.fetchData()
    let runfetchData = this.dataAsync.bind(this)
    window.setTimeout(runfetchData, 8000)
  }

  subcribe() {
    var callbackAsync = this.fetchData.bind(this)
    callbackAsync()
    this.intervalAsyncID = setInterval(callbackAsync, 20000)
  }

  clearSubcription() {
    clearInterval(this.intervalAsyncID)
  }

  fetchData() {
    this.fetchAssetPrice()
    this.fetchDashboard()
  }

  fetchDashboard() {
    fetch(CONFIG.DSE_API_URL + 'dashboard', {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json()
      })
      .then((result) => {
        if (result.code === 0) {
          store.dispatch(handleDashboardData(result.data))
        } else {
          console.log('## dashboard_error')
        }
      })
      .catch((err) => {
        console.log('## dashboard_error', err)
      })
  }

  fetchAssetPrice() {
    fetch(CONFIG.API_URL + 'xrd-usdt', {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify({}),
    })
      .then((response) => {
        return response.json()
      })
      .then((result) => {
        if (result.code === 0) {
          fetch(CONFIG.API_URL + 'xrd-usdc', {
            method: 'GET',
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
          })
            .then((resp) => {
              return resp.json()
            })
            .then((ret) => {
              if (ret.code === 0) {
                store.dispatch(
                  handleAssetPrice({ xrd_usdt: result, xrd_usdc: ret })
                )
                const priceMap = new Map()
                priceMap.set('XRD', Number(result.data.price))
                priceMap.set('USDT', 1)
                priceMap.set(
                  'USDC',
                  Number(ret.data.price) / Number(result.data.price)
                )
                priceMap.set('XRD_IN_XRD', 1)
                priceMap.set('USDT_IN_XRD', 1 / Number(result.data.price))
                priceMap.set('USDC_IN_XRD', 1 / Number(ret.data.price))
                priceMap.set('XRD_IN_USDT', Number(result.data.price))
                priceMap.set('XRD_IN_USDC', Number(ret.data.price))
                store.dispatch(handleAssetPriceMap(priceMap))
              } else {
                console.log('### xrd_usdc_price error ', ret.msg)
              }
            })
            .catch((er) => {
              console.log('### xrd_usdc_price_catch_error', er)
            })
        } else {
          console.log('### xrd_usdt_price error ', result.msg)
        }
      })
      .catch((err) => {
        console.log('## xrd_usdt_price_catch_error', err)
      })
  }
}
