import { WalletDataState } from '@radixdlt/radix-dapp-toolkit'
import { ReducerAction } from 'react';
import { AnyAction, Reducer } from 'redux';

const initState = {
  connected: false,
  walletDataState: {},
  account: ''
}

export enum WalletActionType {
  INIT = 'WALLET.INIT',
  HANDLE_WALLET_DATA = 'WALLET.HANDLE_WALLETDATA',
  CHANGE_ACCOUNT = "WALLET.CHANGE_ACCOUNT"
}

const Wallet = (state = initState, action:AnyAction) => {
  switch (action.type) {
    case WalletActionType.HANDLE_WALLET_DATA:
      const payload: WalletDataState = action.payload
      if (payload && payload.accounts && payload.accounts.length > 0) {
        return { ...state, connected: true, walletDataState: payload }
      } else {
        return { ...state, connected: false, walletDataState: payload }
      }
    case WalletActionType.CHANGE_ACCOUNT:
      return { ...state, account: action.payload }
    default:
      return state
  }
}

export default Wallet
