import { useEffect, useState } from 'react'
import styles from './Stake.module.css'
import Pophelp from '../../components/pophelp/Pophelp'
import { Modal, Input, Alert, Collapse, message } from 'antd'
import { useSelector } from 'react-redux'
import Loading from '../../components/loading/Loading'
import CONFIG from '../../env'
import { useRdt } from '../../hooks/useRdt'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import {
  queryAssetBalance,
  shortTxId,
  formatNumber,
} from '../../utils/ContractDataUtil'
import { StakeValidatorType } from '../../components/Types'
import RedeemAssetTabs from '../../components/redeemAssetTabs/RedeemAssetTabs'
import { RedeemValidatorType } from '../../components/Types'
import {
  CopyOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import copy from 'copy-to-clipboard'

const RedeemModal = ({
  openStatus,
  changeOpenStatus,
}: {
  openStatus: boolean
  changeOpenStatus: Function
}) => {
  const account = useSelector((state: any) => state.Wallet.account)
  const rdt = useRdt()

  const [showSpeedOption, setShowSpeedOption] = useState<boolean>(false)
  const [speed, setSpeed] = useState<'fast' | 'normal'>('fast')
  const [redeemInputValue, setRedeemInputValue] = useState<string>('')
  const [dseBalance, setDseBalance] = useState<number>(0)
  const [xrdAmount, setXrdAmount] = useState<number>(0)
  const [xrdAmountStatus, setXrdAmountStatus] = useState<0 | 1>(0)
  const [txId, setTxId] = useState('')
  // 0未开始 1提交中 2提交成功 3success
  const [transactionStatus, setTransactionStatus] = useState('0')

  const [alertVisible, setAlertVisible] = useState(false)
  const [alertType, setAlertType] = useState<'success' | 'error'>('success')
  const [alertMessage, setAlertMessage] = useState<React.ReactElement>()
  // 状态 1=disable 0=ready 2=loading 3
  const [redeemButtonStatus, setRedeemButtonStatus] = useState<0 | 1 | 2 | 3>(1)
  const [redeemValidatorArray, setRedeemValidatorArray] = useState<
    RedeemValidatorType[]
  >([])

  // assetTab状态：0初始 1加载中 2展示查询结果
  const [redeemAssetTabStatus, setRedeemAssetTabStatus] = useState<0 | 1 | 2>(0)

  let interval: NodeJS.Timeout

  const speedText = (
    <div>
      <p>
        With involvement in the babylon unstake process, the clients must wait
        for 2016 Epochs (usually up to 7 days) after initiating a unstake
        request. Once the claim epoch is reach out, the clients will claim their
        funds(XRD) to their wallets. In this process, no fees need to be
        charged.
      </p>
      <p>
        In the instant redeem process, clients can receive funds immediately,
        but they need to pay the cost of using funds during the unlocked period.
      </p>
      <p>
        Furthermore, in the case that the lending fund pool does not have a
        sufficient amount to support the instant redeem, all the requests will
        be treated as a normal redeem.
      </p>
    </div>
  )

  //状态 1查询中 0返回结果
  const [balanceStatus, setBalanceStatus] = useState(0)

  useEffect(() => {
    initData()
  }, [account])

  const initData = () => {
    fetchBalance()
  }

  const fetchBalance = async () => {
    setBalanceStatus(1)
    if (account !== '') {
      // const value = await queryAssetBalance(rdt, account, CONFIG.XRD_ADDRESS)
      // setBalance(value)
      const dseValue = await queryAssetBalance(
        rdt,
        account,
        CONFIG.DSE_XRD_ADDRESS
      )

      setDseBalance(dseValue)
    }
    setBalanceStatus(0)
  }

  const SpeedSwitchButton = () => {
    if (speed === 'fast') {
      return (
        <div className={styles['speed-div']}>
          <div className={styles['speed-switch']}>
            <div className={styles['switch-overlay']}></div>
            <div className={styles['switch-bt-st']}>Instant</div>
            <div className={styles['switch-bt']} onClick={changeSpeed}>
              Normal
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles['speed-div']}>
          <div className={styles['speed-switch']}>
            <div className={styles['switch-overlay-1']}></div>
            <div className={styles['switch-bt']} onClick={changeSpeed}>
              Instant
            </div>
            <div className={styles['switch-bt-st']}>Normal</div>
          </div>
        </div>
      )
    }
  }

  const changeRedeemInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target
    const reg = /^-?\d*(\.\d*)?$/

    if (reg.test(inputValue) || inputValue === '') {
      setRedeemInputValue(inputValue)
      if (Number(inputValue) > dseBalance) {
        setAlertType('error')
        setAlertVisible(true)
        setAlertMessage(<div>The balance is insufficient</div>)
        setRedeemButtonStatus(1)
      } else if (inputValue === '' || inputValue === '0') {
        setAlertType('success')
        setAlertVisible(false)
        setAlertMessage(<div></div>)
        setRedeemButtonStatus(1)
      } else {
        setAlertType('success')
        setAlertVisible(false)
        setAlertMessage(<div></div>)
        // setRedeemButtonStatus(0)
      }
    }
  }

  const changeSpeed = () => {
    setSpeed(speed === 'fast' ? 'normal' : 'fast')
  }

  const maxRedeemValue = (value: number) => {
    let v = Math.floor(Number(value) * 1000000) / 1000000
    setRedeemInputValue(v.toString())
  }

  const genDseBalanceDiv = () => {
    if (balanceStatus === 1) {
      return (
        <div className={styles['modal-input-overview-two']}>
          balance:{' '}
          <div className={styles['balance-value']}>
            <Loading />
          </div>
          &nbsp;&nbsp; dseXRD
        </div>
      )
    } else {
      return (
        <div className={styles['modal-input-overview-two']}>
          balance:{' '}
          <div
            className={styles['balance-value']}
            onClick={() => {
              maxRedeemValue(dseBalance)
            }}
          >
            {formatNumber(dseBalance)}
            &nbsp;&nbsp; dseXRD
          </div>
        </div>
      )
    }
  }

  const queryTransactionStatus = async (txHash: string) => {
    const result = await rdt?.gatewayApi.transaction.getStatus(txHash)
    if (result?.status) {
      // setTransactionStatus(result.status)
      if (
        result.status === 'CommittedSuccess' ||
        result.status === 'CommittedFailure' ||
        result.status === 'Rejected'
      ) {
        setTransactionStatus('3')
        setRedeemButtonStatus(3)
        clearInterval(interval)

        let alertMessage = (
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: '1rem' }}>
              <CheckCircleOutlined style={{ color: '#1fb871' }} />
            </div>
            <div style={{ marginRight: '0.5rem' }}>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ wordWrap: 'break-word' }}
                href={CONFIG.DASHBOARD_URL + txHash + '/details'}
              >
                {shortTxId(txHash)}
              </a>
            </div>
            <div>
              <CopyOutlined
                onClick={() => {
                  copyTextToClipboard(txId)
                }}
              />
            </div>
          </div>
        )
        setAlertMessage(alertMessage)
      }
    } else {
      setTransactionStatus('Unknown')
      clearInterval(interval)
    }
  }

  const copyTextToClipboard = (text: string) => {
    if (copy(text)) {
      message.success('Copied!')
    }
  }

  const doRedeem = async () => {
    if (!redeemValidatorArray || redeemValidatorArray.length == 0) {
      return
    }

    let manifest = `
      CALL_METHOD
        Address("${account}")
        "withdraw"
        Address("${CONFIG.DSE_XRD_ADDRESS}")
        Decimal("${redeemInputValue}")
      ;
    `
    for (let i = 0; i < redeemValidatorArray.length; i++) {
      let item = redeemValidatorArray[i]
      manifest += `
        TAKE_FROM_WORKTOP
            Address("${CONFIG.DSE_XRD_ADDRESS}")
            Decimal("${item.dse_amount}")
            Bucket("bucket${i + 1}")
        ;
        CALL_METHOD
            Address("${CONFIG.LENDING_COMPONENT}")
            "redeem"
            Address("${item.validator_addr}")
            Bucket("bucket${i + 1}")
            ${speed === 'fast'}
        ;
      `
    }

    manifest += `
      CALL_METHOD
          Address("${account}")
          "deposit_batch"
          Expression("ENTIRE_WORKTOP")
      ;
    `

    console.log(manifest)
    // 事物开始
    setTransactionStatus('1')
    setRedeemButtonStatus(2)

    const result = await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })

    if (result?.isErr()) {
      setTransactionStatus('0')
      setAlertMessage(
        <div>{result.error.message ? result.error.message : ''}</div>
      )
      setAlertType('error')
      setAlertVisible(true)
      setRedeemButtonStatus(0)
      return
    }

    let ret: any = result

    // 事物结束
    setTransactionStatus('2')
    setRedeemButtonStatus(3)
    setTxId(ret.value.transactionIntentHash)

    interval = setInterval(() => {
      queryTransactionStatus(ret.value.transactionIntentHash)
    }, 1000) // 1秒钟轮询一次

    setAlertType('success')
    let tx = ret.value.transactionIntentHash;
    let alertMessage = (
      <div style={{display:'flex',alignContent:'center',alignItems:'center'}}>
        <div style={{marginRight:'1rem'}}>
          {transactionStatus === "3" ? <CheckCircleOutlined style={{color:'#1fb871'}}/> :
          <Loading/>}
          </div>
        <div style={{marginRight:'0.5rem'}}>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ wordWrap: 'break-word' }}
          href={
            CONFIG.DASHBOARD_URL + tx + '/details'
          }
        >
          {shortTxId(tx)}
        </a>
        </div>
        <div>
        <CopyOutlined onClick={()=>{copyTextToClipboard(tx)}}/>
        </div>
      </div>
    )
    setAlertMessage(alertMessage)
    setAlertVisible(true)

    fetchBalance()
  }

  const clearStatus = () => {
    // setJoinInputValue('')
    setRedeemInputValue('')
    // setJoinButtonStatus(1)
    setRedeemButtonStatus(1)
    setAlertVisible(false)
    setAlertMessage(<div></div>)
    setAlertType('success')
  }

  const handleInputFocus = () => {
    setXrdAmount(0)
    setXrdAmountStatus(1)
    setRedeemButtonStatus(1)
    setRedeemAssetTabStatus(1)
  }

  const handleInputBlur = () => {
    setRedeemValidatorArray([])
    setXrdAmount(0)
    setXrdAmountStatus(1)
    setRedeemButtonStatus(1)
    setRedeemAssetTabStatus(1)
    if (Number(redeemInputValue) > 0) {
      fetch(CONFIG.DSE_API_URL + 'redeem/' + redeemInputValue, {
        method: 'GET',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          return response.json()
        })
        .then((result) => {
          if (result.code === 0) {
            setRedeemValidatorArray(result.data)
            // setXrdAmount(result.data.dse_amount)
            setXrdAmount(100)
            setXrdAmountStatus(0)
            setRedeemButtonStatus(0)
            setRedeemAssetTabStatus(2)
          } else {
            console.log('## dashboard_error')
          }
        })
        .catch((err) => {
          console.log('## dashboard_error', err)
        })
    } else {
      setXrdAmountStatus(0)
      setRedeemAssetTabStatus(0)
    }
  }

  const genButton = () => {
    if (redeemButtonStatus === 0) {
      return (
        <div className={styles['submit-btn']} onClick={doRedeem}>
          Confirm Redeem
        </div>
      )
    } else if (redeemButtonStatus === 1) {
      return <div className={styles['submit-btn-disable']}>Confirm Redeem</div>
    } else if (redeemButtonStatus === 2) {
      return (
        <div className={styles['submit-btn-disable']}>
          <Loading color="var(--gray-300)" />
        </div>
      )
    } else {
      return (
        <div
          className={styles['submit-btn']}
          onClick={() => {
            changeOpenStatus(false)
          }}
        >
          Close
        </div>
      )
    }
  }

  return (
    <Modal
      title={
        <div className={styles['modal-header']}>
          <span>Redeem</span>
        </div>
      }
      closeIcon={
        <div className={styles['modal-close-icon']}>
          <svg
            _ngcontent-ng-c1377572502=""
            width="18"
            height="18"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles['modal-close-icon-svg']}
          >
            <path
              _ngcontent-ng-c1377572502=""
              d="M1.26957 0.563073C1.07431 0.36781 0.757724 0.36781 0.562462 0.563073C0.3672 0.758335 0.3672 1.07492 0.562462 1.27018L1.26957 0.563073ZM19.7291 20.4368C19.9244 20.6321 20.241 20.6321 20.4362 20.4368C20.6315 20.2416 20.6315 19.925 20.4362 19.7297L19.7291 20.4368ZM20.4362 1.27018C20.6315 1.07492 20.6315 0.758335 20.4362 0.563073C20.241 0.36781 19.9244 0.36781 19.7291 0.563073L20.4362 1.27018ZM0.562462 19.7297C0.3672 19.925 0.3672 20.2416 0.562462 20.4368C0.757724 20.6321 1.07431 20.6321 1.26957 20.4368L0.562462 19.7297ZM0.562462 1.27018L19.7291 20.4368L20.4362 19.7297L1.26957 0.563073L0.562462 1.27018ZM19.7291 0.563073L0.562462 19.7297L1.26957 20.4368L20.4362 1.27018L19.7291 0.563073Z"
            ></path>
          </svg>
        </div>
      }
      maskClosable={true}
      onCancel={() => {
        clearStatus()
        changeOpenStatus(false)
      }}
      footer={null}
      destroyOnClose={true}
      centered={true}
      focusTriggerAfterClose={false}
      open={openStatus}
      afterClose={() => {
        setRedeemInputValue('')
        setAlertVisible(false)
        setRedeemButtonStatus(1)
        setTransactionStatus('0')
        setRedeemAssetTabStatus(0);
        setRedeemValidatorArray([])
      }}
    >
      {transactionStatus === '0' || transactionStatus === '1' ? (
        <>
          <Collapse
            accordion={true}
            bordered={false}
            onChange={() => {
              setShowSpeedOption(!showSpeedOption)
            }}
            items={[
              {
                key: 1,
                label: (
                  <div className={styles['modal-redemption-div']}>
                    <div className={styles['modal-redemption']}>
                      Redemption Option
                    </div>
                    {showSpeedOption ? <UpOutlined /> : <DownOutlined />}
                  </div>
                ),
                children: speedText,
                showArrow: false,
              },
            ]}
          />
          <SpeedSwitchButton />
          <div className={styles['modal-input-overview']}>
            <div className={styles['modal-input-overview-one']}>
              Amount to redeem
            </div>
            {genDseBalanceDiv()}
          </div>
          <div className={styles['modal-input-div']}>
            <Input
              className={styles['modal-input']}
              value={redeemInputValue}
              onChange={changeRedeemInputValue}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            ></Input>
          </div>
        </>
      ) : (
        <div></div>
      )}
      <div>
        {alertVisible && (
          <div className={styles['modal-alert-div']}>
            <Alert
              message={alertMessage}
              type={alertType}
              style={{ width: '100%' }}
              closable
              afterClose={() => {
                setAlertVisible(false)
                setAlertType('success')
                setAlertMessage(<div></div>)
                setTxId('')
              }}
            />
          </div>
        )}
        <div className={styles['line']}></div>
        <div className={styles['modal-get-div']}>
          {transactionStatus === '0' || transactionStatus === '1' ? (
            <RedeemAssetTabs
              type={speed}
              status={redeemAssetTabStatus}
              redeemValidatorArray={redeemValidatorArray}
              changeStatus={setRedeemAssetTabStatus}
            />
          ) : (
            <></>
          )}
        </div>
        <div className={styles['modal-input-overview']}>{genButton()}</div>
      </div>
      {/* </div> */}
    </Modal>
  )
}

export default RedeemModal
