import { useState, useEffect } from 'react'
import styles from './Header.module.css'
import AccountPicker from '../accountPicker/AccountPicker'
import { useSelector } from 'react-redux'
import { WalletDataState } from '@radixdlt/radix-dapp-toolkit'
import { changeAccount } from '../../actions/WalletAction'
import { useDispatch } from 'react-redux'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'radix-connect-button': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

const Header = ({device}:{device:'desktop'|'tablet'|'laptop'|'mobile'}) => {
  const walletData = useSelector((state:any)=>state.Wallet);
  const account = useSelector((state:any)=>state.Wallet.account);
  const dispatch = useDispatch();
  const [accounts, setAccounts] = useState([]);
  const onAccountSelected = (address:string):void => {
    dispatch(changeAccount(address));
  }

  useEffect(()=>{
    if(walletData.connected){
      setAccounts(walletData.walletDataState.accounts);
    }
  },[walletData])

  const genAccountPicker = ()=>{
    if(account === ''){
      return <></>
    }else{
      return <div className={styles['acct-div']}><AccountPicker accounts={accounts} selected={account} onSelected={onAccountSelected}/></div>
    }
  }

  return (
    <header className={'flex flex-col items-center'}>
      <nav
        className={'flex flex-row items-center ml-2 mr-2 justify-between pt-1'}
      >
        <div className={'flex flex-row items-center justify-start gap-1'}>
          <img src="/dexian.png" width="35" height={35} />
          <div>
            <p className={styles['text-primary']}>DeXian</p>
          </div>
          <div>
            <p className={styles['text-primary']}><a href="https://docs.dexian.io/docs/dse/whats_dexian">FAQ</a></p>
          </div>
        </div>
        <div className={'flex items-center gap-1'} style={{marginLeft:'10px'}}>
          {device !== 'mobile' ? 
          genAccountPicker()
          :<></>}
          <radix-connect-button></radix-connect-button>
        </div>
      </nav>
      {device === 'mobile'?
        <div className={styles['acct-div']}>
          <div style={{width: '100%'}}><AccountPicker accounts={accounts} selected={account} onSelected={onAccountSelected}/>
          </div>
        </div>:<></>}
    </header>
  )
}

export default Header
