import { Modal, Popover, Input, Collapse } from 'antd'
import { ReactNode, useState } from 'react'

const Pophelp = ({
  title,
  content,
}: {
  title: string
  content: ReactNode
})=>{
  return (
    <Popover
      content={content}
      title={title}
      style={{ backgroundColor: '#f3f6ff' }}
    >
      <span className="material-symbols-outlined">help</span>
    </Popover>
  )
}

export default Pophelp