import { WalletActionType } from '../reducers/Wallet'
import { WalletDataState } from '@radixdlt/radix-dapp-toolkit'

export const handleWalletData = (walletDataState: WalletDataState) => {
  return {
    type: WalletActionType.HANDLE_WALLET_DATA,
    payload: walletDataState,
  }
}

export const changeAccount = (account:string) => {
  return {
    type: WalletActionType.CHANGE_ACCOUNT,
    payload: account
  }
}
