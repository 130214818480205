import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { ReactNode } from 'react'
import styles from './RedeemAssetTabs.module.css'
import './RedeemAssetTabs.css'
import { RedeemValidatorType } from '../../components/Types'
import Loading from '../loading/Loading'
import {
  queryAssetBalance,
  queryAssetStates,
  genPoolDataByAssetSate,
  shortTxId,
  formatNumber,
  numberToFactor,
} from '../../utils/ContractDataUtil'

const RedeemAssetTabs = ({
  type,
  status,
  redeemValidatorArray,
  changeStatus,
}: {
  type: string
  status: number
  redeemValidatorArray: RedeemValidatorType[]
  changeStatus: Function
}) => {
  const Tab = ({ label }: { label: string }) => {
    return <div className={styles.tab}>{label}</div>
  }

  const TabContent = ({ content }: { content: RedeemValidatorType }) => {
    let fee = Number(content.fee)
    let xrdAmount = Number(content.xrd_amount)
    return (
      <div className={styles['tab-content']}>
        <div className={styles['tab-content-info']}>
          <div className={styles['tab-content-info-div']}>
            <span className={styles['tab-content-info-div-spanl']}>
              amount(XRD):{' '}
            </span>
            <span className={styles['tab-content-info-div-spanr']}>
              {xrdAmount}
            </span>
          </div>
          <div className={styles['tab-content-info-div']}>
            <span className={styles['tab-content-info-div-spanl']}>
              date time(EOCH):{' '}
            </span>
            <span className={styles['tab-content-info-div-spanr']}>
              {formatMillisecondsToDate(content.claim_datetime * 1000)}(
              {content.claim_epoch})
            </span>
          </div>
        </div>
      </div>
    )
  }

  function formatMillisecondsToDate(millis: number): string {
    // 创建一个Date对象，传入毫秒数
    const date = new Date(millis)

    // 格式化日期为 'yyyy-MM-dd HH:mm:ss' 格式
    const year = date.getFullYear()
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    const hours = ('0' + date.getHours()).slice(-2)
    const minutes = ('0' + date.getMinutes()).slice(-2)
    const seconds = ('0' + date.getSeconds()).slice(-2)

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  // const FastContent = () => {
  //   return <TabContent content={<InstantContent />} />
  // }

  const InstantContent = () => {
    let totalAmount = redeemValidatorArray.reduce((accumulator, currentObj) => {
      return accumulator + Number(currentObj.xrd_amount)
    }, 0)
    let sumFee = redeemValidatorArray.reduce((accumulator, currentObj) => {
      return accumulator + Number(currentObj.fee)
    }, 0)
    let receiveAmount = totalAmount - sumFee
    return (
      <div className={styles['tab-content-info']}>
        <div className={styles['tab-content-info-div']}>
          <span className={styles['tab-content-info-div-spanl']}>
            amount(XRD):{' '}
          </span>
          <span className={styles['tab-content-info-div-spanr']}>
            {formatNumber(receiveAmount)}
          </span>
        </div>
        {/* <div className={styles['tab-content-info-div']}>
          <span className={styles['tab-content-info-div-spanl']}>
            date time:{' '}
          </span>
          <span className={styles['tab-content-info-div-spanr']}>10000</span>
        </div> */}
        <div className={styles['tab-content-info-div']}>
          <span className={styles['tab-content-info-div-spanl']}>
            fee(XRD):{' '}
          </span>
          <span className={styles['tab-content-info-div-spanr']}>
            {formatNumber(sumFee)}
          </span>
        </div>

        {/* <div className={styles['tab-content-info-div']}>
          <span className={styles['tab-content-info-div-spanl']}>
            receive :{' '}
          </span>
          <span className={styles['tab-content-info-div-spanr']}>10000</span>
        </div> */}
      </div>
    )
  }

  const genTabLabel = (redeemValidator: RedeemValidatorType, id: string) => {
    console.log(redeemValidator)
    if (redeemValidator.validator_name) {
      if (redeemValidator.validator_name.length <= 10) {
        return redeemValidator.validator_name
      } else {
        let prefix = redeemValidator.validator_name.slice(0, 4)
        let suffix = redeemValidator.validator_name.slice(-6)
        return prefix + '...' + suffix
      }
    } else if (redeemValidator.validator_addr) {
      let prefix = redeemValidator.validator_addr.slice(0, 4)
      let suffix = redeemValidator.validator_addr.slice(-6)
      return prefix + '...' + suffix
    } else {
      return 'Tab' + id
    }
  }

  const NormalContent = () => {
    return (
      <Tabs
        items={redeemValidatorArray.map((value, i) => {
          const id = String(i + 1)
          return {
            key: id,
            label: <Tab label={genTabLabel(value, id)} />,
            children: <TabContent content={value} />,
          }
        })}
        defaultActiveKey="1"
        style={{ color: '#fff' }}
      />
    )
  }

  const content = () => {
    if (status == 0) {
      return (
        <div>
          <span className={styles['modal-get-span']}>
            <span className={styles['modal-get-span']}>
              You will receive the following{' '}
              {type === 'fast' ? 'XRD' : 'ClaimNFTs'}:
            </span>
            {type === 'fast' ? (
              <div className={styles['tab-content-info']}>
                <div className={styles['tab-content-info-div']}>
                  <span className={styles['tab-content-info-div-spanl']}>
                    amount(XRD):{' '}
                  </span>
                  <span className={styles['tab-content-info-div-spanr']}>
                    0
                  </span>
                </div>
                <div className={styles['tab-content-info-div']}>
                  <span className={styles['tab-content-info-div-spanl']}>
                    fee(XRD):{' '}
                  </span>
                  <span className={styles['tab-content-info-div-spanr']}>
                    0
                  </span>
                </div>
              </div>
            ) : (
              <div className={styles['tab-content-info']}>
                <div className={styles['tab-content-info-div']}>
                  <span className={styles['tab-content-info-div-spanl']}>
                    amount(XRD):{' '}
                  </span>
                  <span className={styles['tab-content-info-div-spanr']}>
                    0
                  </span>
                </div>
                <div className={styles['tab-content-info-div']}>
                  <span className={styles['tab-content-info-div-spanl']}>
                    date time(EPOCH):{' '}
                  </span>
                  <span className={styles['tab-content-info-div-spanr']}></span>
                </div>
              </div>
            )}
          </span>
        </div>
      )
    } else if (status == 1) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '36px',
          }}
        >
          <Loading />
        </div>
      )
    } else {
      return (
        <div>
          <span className={styles['modal-get-span']}>
            You will receive the following{' '}
            {type === 'fast' ? 'XRD' : 'ClaimNFTs'}:
          </span>
          {type === 'fast' ? <InstantContent /> : <NormalContent />}
        </div>
      )
    }
  }

  return content()
}

export default RedeemAssetTabs
