import { DashboardType } from "@/components/Types";
import Common, { CommonActionType } from "../reducers/Common";

export function handleAssetPrice(data:any){
  return {
    type: CommonActionType.SET_PRICE,
    payload: data,
  }
}

export function handleAssetPriceMap(data:any){
  return {
    type: CommonActionType.SET_PRICE_MAP,
    payload: data
  }
}

export function handleDashboardData(data:any){
  return {
    type: CommonActionType.SET_DASHBOARD,
    payload: data
  }
}

export function fetchAccountDashboard(account:string){
  return {
    type: CommonActionType.FETCH_ACCOUNT_DASHBOARD,
    payload: account
  }
}

export function handleAccountDashboardData(data:DashboardType){
  return {
    type: CommonActionType.SET_ACCOUNT_DASHBOARD,
    payload: data
  }
}