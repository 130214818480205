import styles from './Home.module.css'
import { useState } from 'react'
import './Home.module.css'
import Stake from '../stake/Stake';
import Pool from '../pool/Pool'

const Home = ({device}:{device:'desktop'|'tablet'|'laptop'|'mobile'}) => {

  const [nav, setNav] = useState<'stake' | 'pool'>('stake')
  

  const changeNav = () => {
    setNav(nav === 'stake' ? 'pool' : 'stake')
  }

  const Nav = () => {
    if (nav === 'stake') {
      return (
        <div className={styles['nav-mt']}>
          <div className={styles.nav}>
            <div className={styles['nav-item']}>
              <a className={styles['nav-a-selected']}>Stake</a>
            </div>
            <div className={styles['nav-item']}>
              <a onClick={changeNav} className={styles['nav-a']}>
                Pool
              </a>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles['nav-mt']}>
          <div className={styles.nav}>
            <div className={styles['nav-item']}>
              <a onClick={changeNav} className={styles['nav-a']}>
                Stake
              </a>
            </div>
            <div className={styles['nav-item']}>
              <a className={styles['nav-a-selected']}>Pool</a>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={`flex flex-col items-center ${styles['mt-3']}`} id="home">
      <div className={styles['home-text']}>DeXian Staking Earning</div>

      <Nav />
      {nav === 'stake' ? <Stake /> : <Pool />}
    </div>
  )
}

export default Home
