import { useEffect, useState } from 'react'
import styles from './Stake.module.css'
import Pophelp from '../../components/pophelp/Pophelp'
import CONFIG from '../../env'
import { useSelector, useDispatch } from 'react-redux'
import { useRdt } from '../../hooks/useRdt'
import {
  queryAssetStates,
  queryAssetBalance,
  genPoolDataByAssetSate,
  formatNumber,
  numberToFactor,
} from '../../utils/ContractDataUtil'
import Loading from '../../components/loading/Loading'
import { PoolData } from '../../components/Types'
import JoinModal from './JoinModal'
import RedeemModal from './RedeemModal'
import { fetchAccountDashboard } from '../../actions/CommonActions'
import { GatewayApiClient } from '@radixdlt/babylon-gateway-api-sdk';

enum PoolStatus {
  INIT,
  READY,
}

const Stake = () => {
  const dispatch = useDispatch();
  const account = useSelector((state: any) => state.Wallet.account)
  const priceMap = useSelector((state: any) => state.Common.price_map)
  const dashboard = useSelector((state: any) => state.Common.dashboard)
  const accountDashboard = useSelector((state:any) => state.Common.accountDashboard)
  const rdt = useRdt()
  
  // const [balance, setBalance] = useState<number>(0);
  const [dseBalance, setDseBalance] = useState<number>(0);
  const [joinModalOpen, setJoinModalOpen] = useState<boolean>(false)
  const [redeemModalOpen, setRedeemModalOpen] = useState<boolean>(false)

  const [poolDataArray, setPoolDataArray] = useState<PoolData[]>([])
  const [poolStatus, setPoolStatus] = useState<PoolStatus>(0)

  useEffect(() => {
    setPoolStatus(0)
    setPoolDataArray([])
    initData()
  }, [])

  useEffect(() => {
    initData()
  }, [account, priceMap, dashboard])

  const initData = async () => {
    if (account) {
      fetchDseBalance()
      dispatch(fetchAccountDashboard(account))
    }
    if (!priceMap) {
      return
    }
    if (priceMap && poolDataArray.length > 0) {
      freshPrice()
    } else {
      queryAssetStates(rdt, handleAssetStates)
    }
  }

  const fetchDseBalance = async () => {
    if (account !== '') {
      const value = await queryAssetBalance(rdt, account, CONFIG.DSE_XRD_ADDRESS)
      setDseBalance(value)
    }
  }

  const freshPrice = () => {
    if (poolDataArray.length > 0 && priceMap) {
      poolDataArray.forEach((item) => {
        item.price = priceMap.get(item.symbol)
        item.priceInXrd = priceMap.get(item.symbol + '_in_XRD')
      })
    }
  }

  const handleAssetStates = async (assetStates: any) => {
    var taskWatcher = assetStates.length
    let dataArray: PoolData[] = []

    assetStates.forEach((assetState: any) => {
      const poolDataResult = genPoolDataByAssetSate(rdt, assetState, priceMap)
      poolDataResult.then((poolData) => {
        if (poolData !== undefined) {
          dataArray?.push(poolData)
          taskWatcher--
          dataArray.sort((a, b) => a.sort - b.sort)
        }
        if (taskWatcher === 0) {
          setPoolDataArray(dataArray)
          setPoolStatus(1)
        }
      })
    })
  }

  const showAccountDashboard = ()=>{
    if(account && JSON.stringify(accountDashboard) != '{}'){
      return true;
    }else{
      return false;
    }
  }

  const genAccountEquity = ()=>{
    if (account) {
      if(showAccountDashboard() && poolDataArray.length > 0){
        const xrdPoolData = poolDataArray.find(it=>it.address === CONFIG.XRD_ADDRESS);
        if (xrdPoolData) {
          let equity = dseBalance * xrdPoolData?.supplyIndex
          return formatNumber(equity)
        }
      }else{
        return <Loading color="var(--gray-300)"/>
      }
    }else{
      return '-'
    }
  }

  const genAccountEarning = ()=>{
    if (account) {
      if(showAccountDashboard() && poolDataArray.length > 0){
        const equity = genAccountEquity()
        return formatNumber(Number(equity) + Number(accountDashboard.user.redeem) - Number(accountDashboard.user.join))
      }else{
        return <Loading color="var(--gray-300)"/>
      }
    }else{
      return '-'
    }
  }

  return (
    <div>
      <div className={`${styles['data-text']}`}>
        <div className={`${styles['data-text-pd']}`}>
          <div className={`${styles['state-title']}`}>Indicator  [<span style={{fontSize:'1rem',fontFamily:"'Oswald', sans-serif"}}>
                  {dashboard.epoch}
                </span>]</div>
          <div className={styles['data-text-flex']}>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  <span>APY</span>
                  <Pophelp title="apy" content={<span>Annual percentage yields for DeXian Staking Earning(dseXRD)</span>}></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>
                  {formatNumber(Number(dashboard.apy))}
                </div>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                borderLeft: '1px solid rgb(85, 85, 85)',
              }}
            ></div>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  <span>Unit Net</span>
                  <Pophelp
                    title="Unit Net"
                    content={<span>Net unit value: the XRD value to per dseXRD.</span>}
                  ></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>
                  {formatNumber(Number(dashboard.unit_net))}
                </div>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                borderLeft: '1px solid rgb(85, 85, 85)',
              }}
            ></div>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  <span>SIR</span>
                  <Pophelp
                    title="Supply Interest Rate for XRD Lending Pool"
                    content={<span>Supply Interest Rate for XRD Lending Pool</span>}
                  ></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>
                  {poolStatus == 0 ? (
                    <Loading color="var(--gray-300)" />
                  ) : (
                    numberToFactor(
                      poolDataArray.find(
                        (item) => item.address === CONFIG.XRD_ADDRESS
                      )?.supplyInterestRate
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.divider}></div>

        <div className={`${styles['data-text-pd']}`}>
          <div className={`${styles['state-title']}`}>Global Stats</div>
          <div className={styles['data-text-flex']}>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  Equity
                  <Pophelp title="Capitalization" content={<span>DeXian Staking Earning Total Market Capitalization (based XRD)</span>}></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>
                  {formatNumber(Number(dashboard.equity))}
                </div>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                borderLeft: '1px solid rgb(85, 85, 85)',
              }}
            ></div>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  Earning
                  <Pophelp title="Earned" content={<span>DeXian Staking Earning overall amount of returns(XRD).</span>}></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>
                  {formatNumber(Number(dashboard.earning))}
                </div>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                borderLeft: '1px solid rgb(85, 85, 85)',
              }}
            ></div>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  Holding
                  <Pophelp title="Holding" content={<span>the current supply of dseXRD.</span>}></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>
                  {formatNumber(Number(dashboard.holding))}
                </div>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                borderLeft: '1px solid rgb(85, 85, 85)',
              }}
            ></div>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  Join
                  <Pophelp title="Join" content={<span>The overall amout(XRD) of joined in DeXian Staking Earning.</span>}></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>
                  {formatNumber(Number(dashboard.join))}
                </div>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                borderLeft: '1px solid rgb(85, 85, 85)',
              }}
            ></div>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  Redeem
                  <Pophelp title="redeem" content={<span>The overall amount(XRD) of redemptions for DeXian Staking Earning.</span>}></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>
                  {formatNumber(Number(dashboard.redeem))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.divider}></div>

        <div className={`${styles['data-text-pd']}`}>
          <div className={`${styles['state-title']}`}>
            <span>Account Stats</span>
          </div>
          <div className={styles['data-text-flex']}>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  Equity
                  <Pophelp title="equity" content={<span>Market value(XRD) of current wallet address holding dseXRD.</span>}></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>{genAccountEquity()}</div>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                borderLeft: '1px solid rgb(85, 85, 85)',
              }}
            ></div>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  Earning
                  <Pophelp title="Earned" content={<span>DeXian Staking Earning overall amount of returns (XRD) for current wallet address.</span>}></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>{genAccountEarning()}</div>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                borderLeft: '1px solid rgb(85, 85, 85)',
              }}
            ></div>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  Holding
                  <Pophelp title="Holding" content={<span>the amount(dseXRD) of holding by current wallet address.</span>}></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>{account ? formatNumber(dseBalance):'-'}</div>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                borderLeft: '1px solid rgb(85, 85, 85)',
              }}
            ></div>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  Join
                  <Pophelp title="Join" content={<span>the total amount (XRD) of join by current wallet address.</span>}></Pophelp>
                </div>
                <div className={`${styles['state-item-data']}`}>{showAccountDashboard() ? formatNumber(Number(accountDashboard.user.join)) : '-'}</div>
              </div>
            </div>
            <div
              style={{
                width: '1px',
                borderLeft: '1px solid rgb(85, 85, 85)',
              }}
            ></div>
            <div className={`${styles['state-data']}`}>
              <div className="flex flex-col">
                <div className={`${styles['state-item-title']}`}>
                  Redeem
                  <Pophelp title="Redeem" content={<span>the total amount of redeem(XRD) by current wallet address.</span>}></Pophelp>
                  </div>
                <div className={`${styles['state-item-data']}`}>{showAccountDashboard() ? formatNumber(Number(accountDashboard.user.redeem)) : '-'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles['bt-container']}>
        <div
          className={styles['bt-div']}
          onClick={() => {
            setJoinModalOpen(true)
          }}
        >
          <button className={styles['bt']}>Join</button>
        </div>

        <div
          className={styles['bt-div']}
          onClick={() => {
            setRedeemModalOpen(true)
          }}
        >
          <button className={styles['bt']}>Redeem</button>
        </div>
      </div>

      <JoinModal
        openStatus={joinModalOpen}
        changeOpenStatus={setJoinModalOpen}
      />

      <RedeemModal
        openStatus={redeemModalOpen}
        changeOpenStatus={setRedeemModalOpen}
      />
    </div>
  )
}
export default Stake
