export async function genInternalKeyStoreData(key_value_store_address: string) {
  const response = await fetch(
    'https://cors.redoc.ly/https://stokenet.radixdlt.com/state/key-value-store/keys',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        key_value_store_address: key_value_store_address,
      }),
    }
  )
  const data = await response.json()

  let keys: any = []
  data.items.forEach((it: any) => {
    keys.push({ key_hex: it.key.raw_hex })
  })
  const response2 = await fetch(
    'https://cors.redoc.ly/https://stokenet.radixdlt.com/state/key-value-store/data',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        key_value_store_address: key_value_store_address,
        keys: keys,
      }),
    }
  )
  const data2 = await response2.json()
  return data2;

}
