import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
// import App from './App';
import App from './pages/App'
import reportWebVitals from './reportWebVitals'
import './global.css'
import {
  DataRequestBuilder,
  RadixDappToolkit,
  RadixNetwork,
} from '@radixdlt/radix-dapp-toolkit'
import { RdtProvider } from './RdtProvider'
import { Provider } from 'react-redux'
import { store } from './store/configureStore.js'
import ApiService from './services/ApiService'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const apiService = new ApiService({ interval: true })
apiService.subcribe()
apiService.fetchAssetPrice()

const dAppId =
  'account_tdx_2_129th30gyg5w0fh06swecmtg6ddcqfl77qme7ffvqzrgwc7kyelr5tp'
// Instantiate DappToolkit
const rdt = RadixDappToolkit({
  dAppDefinitionAddress: dAppId,
  networkId: RadixNetwork.Stokenet, // network ID 2 is for the stokenet test network 1 is for mainnet
  applicationName: 'DeXian DSE',
  applicationVersion: '1.0.0',
})

console.log('dApp Toolkit: ', rdt)

rdt.walletApi.setRequestData(DataRequestBuilder.accounts())

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RdtProvider value={rdt}>
        <App />
      </RdtProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
