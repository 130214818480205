import { useEffect, useState } from 'react'
import styles from './Stake.module.css'
import Pophelp from '../../components/pophelp/Pophelp'
import { Modal, Input, Alert, message } from 'antd'
import { useSelector } from 'react-redux'
import Loading from '../../components/loading/Loading'
import CONFIG from '../../env'
import { useRdt } from '../../hooks/useRdt'
import {
  queryAssetBalance,
  queryAssetStates,
  genPoolDataByAssetSate,
  shortTxId,
  formatNumber,
  numberToFactor,
} from '../../utils/ContractDataUtil'
import { StakeValidatorType } from '../../components/Types'
import { StringifyOptions } from 'querystring'
import { CopyOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';

const JoinModal = ({
  openStatus,
  changeOpenStatus,
}: {
  openStatus: boolean
  changeOpenStatus: Function
}) => {
  const account = useSelector((state: any) => state.Wallet.account)
  const rdt = useRdt()

  const [inputValue, setInputValue] = useState<string>('')
  const [alertMessage, setAlertMessage] = useState<React.ReactElement>()
  const [alertType, setAlertType] = useState<'success' | 'error'>('success')
  const [alertVisible, setAlertVisible] = useState(false)
  // 状态 1=disable 0=ready 2=loading 3=close
  const [buttonStatus, setButtonStatus] = useState<0 | 1 | 2 | 3>(1)
  //状态 1查询中 0返回结果
  const [balanceStatus, setBalanceStatus] = useState(0)
  const [txId, setTxId] = useState('')
  // 0未开始 1提交中 2提交成功 3success
  const [transactionStatus, setTransactionStatus] = useState<string>('0')
  const [balance, setBalance] = useState<number>(0)

  const [dseAmount, setDseAmount] = useState<number>(0)
  // 1加载中 0加载完成显示
  const [dseAmountStatus, setDseAmountStatus] = useState<0 | 1>(0)

  // 后端返回的stateArray
  const [stakeValidatorArray, setStakeValidatorArray] = useState<
    StakeValidatorType[]
  >([])

  let interval: NodeJS.Timeout

  useEffect(() => {
    initData()
  }, [account])

  const initData = () => {
    fetchBalance()
  }

  const maxJoinValue = (value: number) => {
    setInputValue(value.toString())
    handleInputBlur()
  }
  const genBalanceDiv = () => {
    if (balanceStatus === 1) {
      return (
        <div className={styles['modal-input-overview-two']}>
          balance:{' '}
          <div className={styles['balance-value']}>
            <Loading />
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles['modal-input-overview-two']}>
          balance:{' '}
          <div
            className={styles['balance-value']}
            onClick={() => {
              maxJoinValue(balance)
            }}
          >
            {formatNumber(balance)}
          </div>
        </div>
      )
    }
  }

  const changeJoinInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target
    const reg = /^-?\d*(\.\d*)?$/

    if (reg.test(inputValue) || inputValue === '') {
      setInputValue(inputValue)
      if (Number(inputValue) > balance) {
        setAlertType('error')
        setAlertVisible(true)
        setAlertMessage(<div>The balance is insufficient</div>)
        // setButtonStatus(1)
      } else if (inputValue === '' || inputValue === '0') {
        setAlertType('success')
        setAlertVisible(false)
        setAlertMessage(<div></div>)
        // setButtonStatus(1)
      } else {
        setAlertType('success')
        setAlertVisible(false)
        setAlertMessage(<div></div>)
        // setButtonStatus(0)
      }
    }
  }

  const fetchBalance = async () => {
    setBalanceStatus(1)
    if (account !== '') {
      const value = await queryAssetBalance(rdt, account, CONFIG.XRD_ADDRESS)
      setBalance(value)
      // const dseValue = await queryAssetBalance(rdt, account, CONFIG.DSE_XRD_ADDRESS)
      // setDseBalance(dseValue)
    }
    setBalanceStatus(0)
  }

  const queryTransactionStatus = async (txHash: string) => {
    const result = await rdt?.gatewayApi.transaction.getStatus(txHash)
    if (result?.status) {
      setTransactionStatus(result.status)
      if (
        result.status === 'CommittedSuccess' ||
        result.status === 'CommittedFailure' ||
        result.status === 'Rejected'
      ) {
        setTransactionStatus('3')
        setButtonStatus(3)
        clearInterval(interval)

        let alertMessage = (
          <div style={{display:'flex',alignContent:'center',alignItems:'center'}}>
            <div style={{marginRight:'1rem'}}>
              <CheckCircleOutlined style={{color:'#1fb871'}}/>
            </div>
            <div style={{marginRight:'0.5rem'}}>
            <a
              target="_blank"
              rel="noreferrer"
              style={{ wordWrap: 'break-word' }}
              href={
                CONFIG.DASHBOARD_URL + txHash + '/details'
              }
            >
              {shortTxId(txHash)}
            </a>
            </div>
            <div>
            <CopyOutlined onClick={()=>{copyTextToClipboard(txId)}}/>
            </div>
          </div>
        )
        setAlertMessage(alertMessage)
      }
    } else {
      setTransactionStatus('Unknown')
      clearInterval(interval)
    }
  }

  const copyTextToClipboard = (text:string) => {
    if(copy(text)){
      message.success("Copied!")
    }
  };

  const doJoin = async () => {
    if (!stakeValidatorArray || stakeValidatorArray.length == 0) {
      return
    }

    let manifest = `
          CALL_METHOD
          Address("${account}")
          "withdraw"
          Address("${CONFIG.XRD_ADDRESS}")
          Decimal("${inputValue}")
      ;
    `

    for (let i = 0; i < stakeValidatorArray.length; i++) {
      let stakeItem = stakeValidatorArray[i]
      manifest += `
        TAKE_FROM_WORKTOP
            Address("${CONFIG.XRD_ADDRESS}")
            Decimal("${stakeItem.amount}")
            Bucket("bucket${i}")
        ;
        CALL_METHOD
            Address("${CONFIG.LENDING_COMPONENT}")
            "join"
            Address("${stakeItem.address}")
            Bucket("bucket${i}")
        ;
      `
    }
    manifest += `
      CALL_METHOD
          Address("${account}")
          "deposit_batch"
          Expression("ENTIRE_WORKTOP")
      ;
    `

    console.log(manifest)

    // 事物开始
    setButtonStatus(2)
    setTransactionStatus('1')

    const result = await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })

    if (result?.isErr()) {
      setAlertMessage(
        <div>{result.error.message ? result.error.message : ''}</div>
      )
      setAlertType('error')
      setAlertVisible(true)
      setButtonStatus(0)
      setTransactionStatus('0')
      return
    }

    let ret: any = result

    // 事物结束
    setButtonStatus(3)
    setTransactionStatus("2")
    setTxId(ret.value.transactionIntentHash)

    interval = setInterval(() => {
      queryTransactionStatus(ret.value.transactionIntentHash)
    }, 1000) // 5秒钟轮询一次

    setAlertType('success')
    let tx = ret.value.transactionIntentHash;
    let alertMessage = (
      <div style={{display:'flex',alignContent:'center',alignItems:'center'}}>
        <div style={{marginRight:'1rem'}}>
          {transactionStatus === "3" ? <CheckCircleOutlined style={{color:'#1fb871'}}/> :
          <Loading/>}
          </div>
        <div style={{marginRight:'0.5rem'}}>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ wordWrap: 'break-word' }}
          href={
            CONFIG.DASHBOARD_URL + tx + '/details'
          }
        >
          {shortTxId(tx)}
        </a>
        </div>
        <div>
        <CopyOutlined onClick={()=>{copyTextToClipboard(tx)}}/>
        </div>
      </div>
    )
    setAlertMessage(alertMessage)
    setAlertVisible(true)
    fetchBalance()
  }

  /**
   * Input焦点：
   * dse数量设为0，失去焦点时候时候重新计算；
   * dse数量改为loading状态；
   * join按钮暂时不可用状态
   */
  const handleInputFocus = () => {
    setDseAmount(0)
    setDseAmountStatus(1)
    setButtonStatus(1)
  }

  /**
   * input失去焦点：
   * 根据数据的数量去backend查询可以获得的数量；
   * 重新设置可获得数量；
   * 根据backend返回的数据情况，判断是否join按钮是否可用
   */
  const handleInputBlur = () => {
    setStakeValidatorArray([])
    setDseAmount(0)
    setDseAmountStatus(1)
    setButtonStatus(1)
    if (Number(inputValue) > 0) {
      fetch(CONFIG.DSE_API_URL + 'join/' + inputValue, {
        method: 'GET',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          return response.json()
        })
        .then((result) => {
          if (result.code === 0) {
            setStakeValidatorArray(result.data.stake)
            setDseAmount(result.data.dse_amount)
            setDseAmountStatus(0)
            setButtonStatus(0)
          } else {
            console.log('## dashboard_error')
          }
        })
        .catch((err) => {
          console.log('## dashboard_error', err)
        })
    } else {
      setDseAmountStatus(0)
    }
  }

  const dseAmountDiv = () => {
    if (dseAmountStatus === 1) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Loading />
        </div>
      )
    } else {
      return <div>{formatNumber(Number(dseAmount))} dseXRD</div>
    }
  }

  const genButton = () => {
    if (buttonStatus == 0) {
      return (
        <div className={styles['submit-btn']} onClick={doJoin}>
          Confirm Join
        </div>
      )
    } else if (buttonStatus == 1) {
      return <div className={styles['submit-btn-disable']}>Confirm Join</div>
    } else if (buttonStatus == 2) {
      return (
        <div className={styles['submit-btn-disable']}>
          <Loading color="var(--gray-300)" />
        </div>
      )
    } else {
      return (
        <div
          className={styles['submit-btn']}
          onClick={() => {
            changeOpenStatus(false)
          }}          
        >
          Close
        </div>
      )
    }
  }

  return (
    <Modal
      title={
        <div className={styles['modal-header']}>
          <span>Join</span>
        </div>
      }
      closeIcon={
        <div className={styles['modal-close-icon']}>
          <svg
            _ngcontent-ng-c1377572502=""
            width="18"
            height="18"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles['modal-close-icon-svg']}
          >
            <path
              _ngcontent-ng-c1377572502=""
              d="M1.26957 0.563073C1.07431 0.36781 0.757724 0.36781 0.562462 0.563073C0.3672 0.758335 0.3672 1.07492 0.562462 1.27018L1.26957 0.563073ZM19.7291 20.4368C19.9244 20.6321 20.241 20.6321 20.4362 20.4368C20.6315 20.2416 20.6315 19.925 20.4362 19.7297L19.7291 20.4368ZM20.4362 1.27018C20.6315 1.07492 20.6315 0.758335 20.4362 0.563073C20.241 0.36781 19.9244 0.36781 19.7291 0.563073L20.4362 1.27018ZM0.562462 19.7297C0.3672 19.925 0.3672 20.2416 0.562462 20.4368C0.757724 20.6321 1.07431 20.6321 1.26957 20.4368L0.562462 19.7297ZM0.562462 1.27018L19.7291 20.4368L20.4362 19.7297L1.26957 0.563073L0.562462 1.27018ZM19.7291 0.563073L0.562462 19.7297L1.26957 20.4368L20.4362 1.27018L19.7291 0.563073Z"
            ></path>
          </svg>
        </div>
      }
      maskClosable={true}
      onCancel={() => {
        changeOpenStatus(false)
      }}
      footer={null}
      destroyOnClose={true}
      centered={true}
      focusTriggerAfterClose={false}
      open={openStatus}
      afterClose={() => {
        setInputValue('')
        setAlertVisible(false)
        setButtonStatus(1)
        setTransactionStatus('0')
      }}
    >
      <div>
        {transactionStatus === '0' || transactionStatus == "1" ? (
          <div className={styles['modal-input-overview']}>
            <div className={styles['modal-input-overview-one']}>
              Amount to join
            </div>
            {genBalanceDiv()}
          </div>
        ) : (
          <div></div>
        )}
        {transactionStatus === '0' || transactionStatus === "1"? (
          <div className={styles['modal-input-div']}>
            <Input
              className={styles['modal-input']}
              onChange={changeJoinInputValue}
              value={inputValue}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            ></Input>
          </div>
        ) : (
          <div></div>
        )}
        <div className={styles['line']}></div>

        {alertVisible && (
          <div className={styles['modal-alert-div']}>
            <Alert
              message={alertMessage}
              type={alertType}
              style={{ width: '100%' }}
              closable
              afterClose={() => {
                setAlertVisible(false)
                setAlertType('success')
                setAlertMessage(<div></div>)
                setTxId('')
              }}
            />
          </div>
        )}

        {transactionStatus === '0' || transactionStatus === "1" ? (
          <div className={styles['modal-get-overview']}>
            <div className={styles['modal-input-overview-one']}>
              You will get
            </div>
            <div className={styles['modal-overview-one']}>{dseAmountDiv()}</div>
          </div>
        ) : (
          <div></div>
        )}

        <div className={styles['modal-input-overview']}>{genButton()}</div>
      </div>
      {/* </div> */}
    </Modal>
  )
}

export default JoinModal
