import styles from './Footer.module.css'

const Footer = ({device}:{device:'desktop'|'tablet'|'laptop'|'mobile'}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <div
            style={{ fontWeight: '600', fontSize: '.875rem', color: '#d1d5db' }}
          >
            Join The Community
          </div>
          <div style={{ marginTop: '1rem', display: 'flex', gap: '1rem' }}>
            <a target="_blank" href="https://twitter.com/DeXianProtocol">
              <img src="/social/new_twitter.svg" loading="lazy" alt="Twitter" width={24} height={24}></img>
            </a>
            <a target="_blank" href="https://t.me/dexianio">
              <img
                src="/social/telegram.svg"
                loading="lazy"
                alt="telegram logo"
              ></img>
            </a>
            <a target="_blank" href="https://github.com/dexianprotocol">
              <img
                // src="https://assets-global.website-files.com/6053f7fca5bf627283b582c2/606d8e7c56b29e6c661143eb_github.svg"
                src="/social/github.svg"
                loading="lazy"
                alt=""
              ></img>
            </a>
          </div>
        </div>

        <div>
          <div
            style={{ fontWeight: '600', fontSize: '.875rem', color: '#d1d5db' }}
          >
            Issue Feedback
          </div>
          <div style={{ marginTop: '1rem', display: 'flex', gap: '1rem' }}>
          <a
              target="_blank"
              href="https://github.com/DeXianProtocol/dexian_lending_protocol/issues/new/choose"
            >
            {/*<a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSevm4gma6hQMqAIjEDesPbjUvna4V7RPBtN79yj6nBOv3Uz6A/viewform"
            >*/}
              {/* <img
                style={{ width: '24px', height: '24px', color: '#fff' }}
                src="https://gumball-club.radixdlt.com/_next/static/media/email.c9257a84.svg"
                loading="lazy"
                alt="feedback"
              ></img> */}
              <span className="material-symbols-outlined" style={{color: '#03A9F4', fontSize:'24px'}}>
                mail
              </span>
            </a>
          </div>
        </div>
        <div>
          <a target="_blank" href="https://www.radixdlt.com/full-stack">
            <img
              alt="developer console link"
              loading="lazy"
              width="119.483"
              height="48.081"
              decoding="async"
              data-nimg="1"
              src="https://gumball-club.radixdlt.com/_next/static/media/runs-on-radix.ac01884b.svg"
              style={{ color: 'transparent' }}
            ></img>
          </a>
        </div>
      </div>

      {/* <div className={styles.border}></div> */}

      <div className={styles.copyright}>
        <div>Dexian 2024</div>
        <div style={{ display: 'flex', gap: '1.5rem' }}>
          <a
            style={{
              fontSize: '.75rem',
              // color: '#6b7280;',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            href="https://docs.dexian.io/docs/privacy"
          >
            Privacy Notice
          </a>
          <div
            style={{
              width: '1px',
              height: '1.5rem',
              backgroundColor: '#e5e7eb',
              alignSelf: 'center',
            }}
          ></div>
          <a
            style={{
              fontSize: '.75rem',
              // color: '#6b7280;',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            href="https://docs.dexian.io/docs/terms_conditions"
          >
            Terms and Condition
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
