import styles from './AccountPicker.module.css'
import { Account as AccountType } from '@radixdlt/radix-dapp-toolkit'
import { Select } from 'antd'

const AccountPicker = ({
  accounts,
  selected,
  onSelected,
}: {
  accounts: AccountType[]
  selected: string | undefined
  onSelected: (address: string) => void
}) => {
  const genOptions = () => {
    var itemList: JSX.Element[] = []
    accounts.forEach(
      (acct: { address: string; appearanceId: number; label: string }) => {
        itemList.push(
          <Select.Option
            value={acct.address}
            key={acct.appearanceId}
            className={styles[`appearance-id-${Number(acct.appearanceId) + 1}`]}
            style={{ marginBottom: '4px', borderRadius: '10px' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#FFF',
                flexDirection: 'row',
                fontWeight: '600',
                wordBreak: 'break-all',
                wordWrap: 'break-word',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <p style={{ marginBottom: '0', color: '#FFF' }}>{acct.label}</p>
              <p style={{ marginBottom: '0', color: '#FFF' }}>
                {acct.address.substring(0, 4) +
                  '...' +
                  acct.address.substring(
                    acct.address.length - 6,
                    acct.address.length
                  )}
              </p>
            </div>
          </Select.Option>
        )
      }
    )
    return itemList
  }

  return (
    <>
      {accounts && accounts.length > 0 ? (
        <div className={styles.container}>
          <Select
            bordered={false}
            defaultValue={selected}
            onSelect={onSelected}
            style={{
              width: '100%',
            }}
          >
            {accounts && accounts.length > 0 ? genOptions() : <></>}
          </Select>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default AccountPicker
