const initState = {
    xrd_usdt:{},
    xrd_usdc:{},
    // price_map:{}
    dashboard: {},
    accountDashboard: {}
}


export enum CommonActionType {
    INIT = "COMMON.INIT",
    SET_PRICE = "COMMON.SET_PRICE",
    SET_PRICE_MAP = "COMMON.SET_PRICE_MAP",
    SET_DASHBOARD = "COMMON.SET_DASHBOARD",
    FETCH_ACCOUNT_DASHBOARD = "COMMON.FETCH_ACCOUNT_DASHBOARD",
    SET_ACCOUNT_DASHBOARD = "COMMON.SET_ACCOUNT_DASHBOARD"
}

const Common = (state=initState, action: {type: CommonActionType, payload:any}) => {
    switch (action.type) {
        case CommonActionType.SET_PRICE:
          return {...state, xrd_usdt:action.payload.xrd_usdt, xrd_usdc:action.payload.xrd_usdc}
        case CommonActionType.SET_PRICE_MAP:
          return {...state, price_map:action.payload}
        case CommonActionType.SET_DASHBOARD:
          return {...state, dashboard:action.payload}
        case CommonActionType.SET_ACCOUNT_DASHBOARD:
          return {...state, accountDashboard:action.payload}
        default :
          return state;
    }
}

export default Common;