import React, { useEffect, useState, useContext, createContext } from 'react'
import {
  BrowserRouter,
  HashRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import Home from './home/Home'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import { useRdt } from '../hooks/useRdt'
import { useDispatch } from 'react-redux'
import { WalletDataState } from "@radixdlt/radix-dapp-toolkit";
import { handleWalletData, changeAccount } from '../actions/WalletAction'
import useWindowSize from '../utils/useWindowSize'

const App = () => {
  const rdt = useRdt();
  const windowSize = useWindowSize();
  const [device, setDevice] = useState<'mobile'|'desktop'|'tablet'|'laptop'>('desktop');
  const dispatch = useDispatch();
  rdt?.walletApi.walletData$.subscribe((walletData)=>{
    dispatch(handleWalletData(walletData))
    dispatch(changeAccount(walletData.accounts[0].address))
  });

  useEffect(() => {
    if (windowSize.width === undefined) return
    if (windowSize.width <= 768) {
      setDevice('mobile')
      return
    }
    if (windowSize.width <= 1024) {
      setDevice('tablet')
      return
    }
    if (windowSize.width <= 1200) {
      setDevice('laptop')
      return
    }
    setDevice('desktop')
  }, [windowSize])

  useEffect(() => {
    const walletDataState:WalletDataState|undefined = rdt?.walletApi.getWalletData();
    if(walletDataState && walletDataState.accounts && walletDataState.accounts.length > 0){
      dispatch(handleWalletData(walletDataState));
    }
  }, []);

  return <div>
  <Header device={device}></Header>
    <Home device={device}/>
  <Footer device={device}></Footer>
  </div>
}

export default App
