import { fork, all } from 'redux-saga/effects';
import { watchWallet } from './WalletActions';
import { watchCommon } from './CommonActions';


export default function* root() {
    yield all([
        fork(watchWallet),
        fork(watchCommon)
    ])
};