import { ReactNode, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styles from './Pool.module.css'
import Pophelp from '../../components/pophelp/Pophelp'
import { Modal, Popover, Input, Collapse, Alert } from 'antd'
import { PoolData } from '../../components/Types'
import { useRdt } from '../../hooks/useRdt'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import Loading from '../../components/loading/Loading'
import Decimal from 'decimal.js'
import {
  queryAssetBalance,
  queryAssetStates,
  genPoolDataByAssetSate,
  formatNumber,
  numberToFactor,
  getUtilizationFactorByPoolData,
  numberToPrice,
  shortTxId,
} from '../../utils/ContractDataUtil'
import CONFIG from '../../env'
import { workerData } from 'worker_threads'

const Pool = () => {
  const rdt = useRdt()
  const walletData = useSelector((state: any) => state.Wallet)
  const account = useSelector((state: any) => state.Wallet.account)
  const priceMap = useSelector((state: any) => state.Common.price_map)
  const [poolDataArray, setPoolDataArray] = useState<PoolData[]>([])
  const [poolData, setPoolData] = useState<PoolData>()
  const [balance, setBalance] = useState<number>(0)
  const [dxBalance, setDxBalance] = useState<number>(0)
  const [balanceStatus, setBalanceStatus] = useState<number>(0)
  const [dxBalanceStatus, setDxBalanceStatus] = useState<number>(0)
  const [supplyValue, setSupplyValue] = useState<string>('')
  const [withdrawValue, setWithdrawValue] = useState<string>('')

  const [buttonStatus, setButtonStatus] = useState<0 | 1 | 2>(1) //1disable 0ready 2loading

  const [alertVisible, setAlertVisible] = useState(false)
  const [alertType, setAlertType] = useState<'success' | 'error'>('success')
  const [alertMessage, setAlertMessage] = useState<React.ReactElement>()

  const [manageType, setManageType] = useState<'supply' | 'withdraw'>('supply')

  useEffect(() => {
    initData()
  }, [account, priceMap])

  const initData = async () => {
    fetchBalance()
    if (!priceMap) {
      return
    }
    if (priceMap && poolDataArray.length > 0) {
      freshPrice()
    } else {
      queryAssetStates(rdt, handleAssetStates)
    }
  }

  const fetchBalance = async () => {
    
    setBalanceStatus(1)
    setDxBalanceStatus(1)
    if (account !== '') {
      const value = await queryAssetBalance(rdt, account, CONFIG.XRD_ADDRESS)
      setBalance(value)
      const dxValue = await queryAssetBalance(
        rdt,
        account,
        CONFIG.DX_XRD_ADDRESS
      )
      setDxBalance(dxValue)
    }
    setBalanceStatus(0)
    setDxBalanceStatus(0)
  }

  const freshPrice = () => {
    if (poolDataArray.length > 0 && priceMap) {
      poolDataArray.forEach((item) => {
        item.price = priceMap.get(item.symbol)
        item.priceInXrd = priceMap.get(item.symbol + '_in_XRD')
      })
    }
  }

  const handleAssetStates = async (assetStates: any) => {
    var taskWatcher = assetStates.length
    let dataArray: PoolData[] = []

    assetStates.forEach((assetState: any) => {
      const poolDataResult = genPoolDataByAssetSate(rdt, assetState, priceMap)
      poolDataResult.then((poolData) => {
        if (poolData !== undefined) {
          dataArray?.push(poolData)
          taskWatcher--
          dataArray.sort((a, b) => a.sort - b.sort)
        }
        if (taskWatcher === 0) {
          setPoolDataArray(dataArray)
          // console.log(dataArray)
          setPoolData(
            dataArray.find((item) => item.address === CONFIG.XRD_ADDRESS)
          )
        }
      })
    })
  }

  const onWithdrawValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!walletData.connected) {
      return
    }

    const { value: inputValue } = e.target
    const reg = /^-?\d*(\.\d*)?$/

    if (reg.test(inputValue) || inputValue === '') {
      setWithdrawValue(inputValue)
      if (
        inputValue !== '' &&
        new Decimal(inputValue).comparedTo(new Decimal(dxBalance)) === 1
      ) {
        setAlertType('error')
        setAlertVisible(true)
        setAlertMessage(<div>The balance is insufficient</div>)
        setButtonStatus(1)
      } else if (inputValue === '' || inputValue === '0') {
        setAlertType('success')
        setAlertVisible(false)
        setAlertMessage(<div></div>)
        setButtonStatus(1)
      } else {
        setAlertType('success')
        setAlertVisible(false)
        setAlertMessage(<div></div>)
        setButtonStatus(0)
      }
    }
  }

  const onSupplyValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(walletData)
    if (!walletData.connected) {
      return
    }

    const { value: inputValue } = e.target
    const reg = /^-?\d*(\.\d*)?$/

    if (reg.test(inputValue) || inputValue === '') {
      setSupplyValue(inputValue)
      if (
        inputValue !== '' &&
        new Decimal(inputValue).comparedTo(new Decimal(balance)) === 1
      ) {
        setAlertType('error')
        setAlertVisible(true)
        setAlertMessage(<div>The balance is insufficient</div>)
        setButtonStatus(1)
      } else if (inputValue === '' || inputValue === '0') {
        setAlertType('success')
        setAlertVisible(false)
        setAlertMessage(<div></div>)
        setButtonStatus(1)
      } else {
        setAlertType('success')
        setAlertVisible(false)
        setAlertMessage(<div></div>)
        setButtonStatus(0)
      }
    }
  }

  const doWithdraw = async ()=>{
    setButtonStatus(2)
    let manifest = `
      CALL_METHOD
        Address("${account}")
        "withdraw"
        Address("${CONFIG.DX_XRD_ADDRESS}")
        Decimal("${withdrawValue}");

      TAKE_FROM_WORKTOP
        Address("${CONFIG.DX_XRD_ADDRESS}")
        Decimal("${withdrawValue}")
        Bucket("myBucket");

      CALL_METHOD
          Address("${CONFIG.LENDING_COMPONENT}")
          "withdraw"
          Bucket("myBucket");
      CALL_METHOD
          Address("${account}")
          "deposit_batch"
          Expression("ENTIRE_WORKTOP");
    `

    console.log(manifest)
    const result = await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })
    if (result?.isErr()) {
      setButtonStatus(0)
      setAlertMessage(<div>{result.error.message}</div>)
      setAlertType('error')
      setAlertVisible(true)
      return
    }
    setButtonStatus(0)
    fetchBalance()

    let ret: any = result
    let alertMessage = (
      <div>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ wordWrap: 'break-word' }}
          href={
            CONFIG.DASHBOARD_URL + ret.value.transactionIntentHash + '/details'
          }
        >
          {shortTxId(ret.value.transactionIntentHash)}
          {/* {ret.value.transactionIntentHash} */}
        </a>
      </div>
    )
    setAlertMessage(alertMessage)
    setAlertType('success')
    setAlertVisible(true)

    // 重新加载数据
    queryAssetStates(rdt, handleAssetStates)
  }

  const doSupply = async () => {
    setButtonStatus(2)
    let manifest = `
      CALL_METHOD
        Address("${account}")
        "withdraw"
        Address("${CONFIG.XRD_ADDRESS}")
        Decimal("${supplyValue}");

      TAKE_FROM_WORKTOP
        Address("${CONFIG.XRD_ADDRESS}")
        Decimal("${supplyValue}")
        Bucket("supplyBucket");

      CALL_METHOD
          Address("${CONFIG.LENDING_COMPONENT}")
          "supply"
          Bucket("supplyBucket");
      CALL_METHOD
          Address("${account}")
          "deposit_batch"
          Expression("ENTIRE_WORKTOP");
    `

    console.log(manifest)
    const result = await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })
    if (result?.isErr()) {
      setButtonStatus(0)
      setAlertMessage(<div>{result.error.message}</div>)
      setAlertType('error')
      setAlertVisible(true)
      return
    }
    setButtonStatus(0)
    fetchBalance()

    let ret: any = result
    let alertMessage = (
      <div>
        <a
          target="_blank"
          rel="noreferrer"
          style={{ wordWrap: 'break-word' }}
          href={
            CONFIG.DASHBOARD_URL + ret.value.transactionIntentHash + '/details'
          }
        >
          {shortTxId(ret.value.transactionIntentHash)}
          {/* {ret.value.transactionIntentHash} */}
        </a>
      </div>
    )
    setAlertMessage(alertMessage)
    setAlertType('success')
    setAlertVisible(true)

    // 重新加载数据
    queryAssetStates(rdt, handleAssetStates)
  }

  const changeManageType = () => {
    setManageType(manageType === 'supply' ? 'withdraw' : 'supply')
    clearStatus()
  }

  const clearStatus = () => {
    setSupplyValue('')
    setWithdrawValue('')

    setButtonStatus(1)
    setAlertVisible(false)
    setAlertMessage(<div></div>)
    setAlertType('success')
  }

  const SwitchButton = () => {
    if (manageType === 'supply') {
      return (
        <div className={styles['manage']}>
          <div className={styles.switch}>
            <div className={styles['switch-overlay']}></div>
            <div className={styles['switch-bt-st']}>Supply</div>
            <div className={styles['switch-bt']} onClick={changeManageType}>
              Withdraw
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles['manage']}>
          <div className={styles.switch}>
            <div className={styles['switch-overlay-1']}></div>
            <div className={styles['switch-bt']} onClick={changeManageType}>
              Supply
            </div>
            <div className={styles['switch-bt-st']}>Withdraw</div>
          </div>
        </div>
      )
    }
  }

  const DxBalanceDiv = () => {
    if (dxBalanceStatus === 0) {
      return (
        <div className={styles['overview-title-1']}>
          balance:{' '}
          <div
            className={styles['balance-value']}
            onClick={() => {
              setWithdrawValue(dxBalance.toString())
              setButtonStatus(0)
            }}
          >
            {formatNumber(dxBalance)}
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles['overview-title-1']}>
          balance:{' '}
          <div className={styles['balance-value']}>
            <Loading fontSize={6} />
          </div>
        </div>
      )
    }
  }

  const BalanceDiv = () => {
    if (balanceStatus === 0) {
      return (
        <div className={styles['overview-title-1']}>
          balance:{' '}
          <div
            className={styles['balance-value']}
            onClick={() => {
              setSupplyValue(balance.toString())
            }}
          >
            {formatNumber(balance)}
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles['overview-title-1']}>
          balance:{' '}
          <div className={styles['balance-value']}>
            <Loading fontSize={6} />
          </div>
        </div>
      )
    }
  }

  return (
    <div className={`${styles['grid']}`}>
      <div className={styles['grid-left']}>
        <div className={styles['grid-left-head']}>XRD</div>
        <div className={styles['line']}></div>
        <div className={styles['grid-left-item']}>
          <div className={styles['grid-left-line']}>
            <div className={styles['grid-left-line-t']}>
              Supply rate
              <Pophelp
                content={<span>Supply rate</span>}
                title={'Supply Rate'}
              ></Pophelp>
            </div>
            <div className={styles['grid-left-line-df']}>
              {poolData ? (
                numberToFactor(poolData.supplyInterestRate)
              ) : (
                <Loading fontSize={36} />
              )}
            </div>
          </div>
          <div className={styles['grid-left-line']}>
            <div className={styles['grid-left-line-t']}>
              borrow rate
              <Pophelp
                content={<span>Borrow rate</span>}
                title={'Borrow Rate'}
              ></Pophelp>
            </div>
            <div className={styles['grid-left-line-df']}>
              {poolData ? (
                numberToFactor(poolData.borrowInterestRate)
              ) : (
                <Loading fontSize={36} />
              )}
            </div>
          </div>
          <div className={styles['grid-left-line']}>
            <div className={styles['grid-left-line-t']}>
              total supply
              <Pophelp
                content={<span>Total supply</span>}
                title={'Total supply'}
              ></Pophelp>
            </div>
            <div className={styles['grid-left-line-d']}>
              {poolData ? (
                poolData.tokenTotalSupply.toFixed(8)
              ) : (
                <Loading fontSize={36} />
              )}
            </div>
          </div>
          <div className={styles['grid-left-line']}>
            <div className={styles['grid-left-line-t']}>
              Utilization factor
              <Pophelp
                content={<span>Utilization factor</span>}
                title={'Utilization factor'}
              ></Pophelp>
            </div>
            <div className={styles['grid-left-line-df']}>
              {poolData ? (
                numberToFactor(getUtilizationFactorByPoolData(poolData))
              ) : (
                <Loading fontSize={36} />
              )}
            </div>
          </div>
          <div className={styles['grid-left-line']}>
            <div className={styles['grid-left-line-t']}>
              Asset price
              <Pophelp
                content={<span>Asset price</span>}
                title={'Asset price'}
              ></Pophelp>
            </div>
            <div className={styles['grid-left-line-d']}>
              {poolData ? (
                numberToPrice(poolData.price, 6)
              ) : (
                <Loading fontSize={36} />
              )}
            </div>
          </div>
        </div>
        {/* <div className={styles['line']}></div>
        <div className={styles['grid-left-own']}>
          <div className={styles['grid-left-own-t']}>Your supply</div>
          <div className={styles['grid-left-own-d']}>12345</div>
        </div> */}
      </div>
      <div className={styles['grid-left']}>
        <SwitchButton />
        {manageType === 'supply' ? (
          <>
            <div className={styles['amount-ipt-overview']}>
              <div className={styles['overview-title']}>Amount to supply</div>
              <BalanceDiv />
            </div>
            <div className={styles['amount-ipt-div']}>
              <Input
                className={styles['modal-input']}
                onChange={onSupplyValueChange}
                value={supplyValue}
              ></Input>
            </div>

            <div className={styles['line']}></div>
            {alertVisible && (
              <div className={styles['modal-alert-div']}>
                <Alert
                  message={alertMessage}
                  type={alertType}
                  style={{ width: '100%' }}
                  closable
                  afterClose={() => {
                    setAlertVisible(false)
                    setAlertType('success')
                    setAlertMessage(<div></div>)
                  }}
                />
              </div>
            )}
            {/* <div className={styles['amount-ipt-overview']}>
              <div className={styles['overview-title']}>You will get</div>
              <div className={styles['overview-title']}>1000</div>
            </div> */}
            <div className={styles['amount-ipt-overview']}>
              {buttonStatus === 0 ? (
                <div className={styles['submit-btn']} onClick={doSupply}>
                  Supply
                </div>
              ) : buttonStatus === 1 ? (
                <div className={styles['submit-btn-disable']}>Supply</div>
              ) : (
                <div className={styles['submit-btn-disable']}>
                  <Loading />
                </div>
              )}
              {/* <div className={styles['submit-btn']}>supply</div> */}
            </div>
          </>
        ) : (
          <>
            <div className={styles['amount-ipt-overview']}>
              <div className={styles['overview-title']}>Amount to withdraw</div>
              <DxBalanceDiv />
            </div>
            <div className={styles['amount-ipt-div']}>
              <Input
                className={styles['modal-input']}
                onChange={onWithdrawValueChange}
                value={withdrawValue}
              ></Input>
            </div>
            <div className={styles['line']}></div>
            {alertVisible && (
              <div className={styles['modal-alert-div']}>
                <Alert
                  message={alertMessage}
                  type={alertType}
                  style={{ width: '100%' }}
                  closable
                  afterClose={() => {
                    setAlertVisible(false)
                    setAlertType('success')
                    setAlertMessage(<div></div>)
                  }}
                />
              </div>
            )}
            {/* <div className={styles['amount-ipt-overview']}>
              <div className={styles['overview-title']}>You will get</div>
              <div className={styles['overview-title']}>1000</div>
            </div> */}
            <div className={styles['amount-ipt-overview']}>
            {buttonStatus === 0 ? (
                <div className={styles['submit-btn']} onClick={doWithdraw}>
                  Withdraw
                </div>
              ) : buttonStatus === 1 ? (
                <div className={styles['submit-btn-disable']}>Withdraw</div>
              ) : (
                <div className={styles['submit-btn-disable']}>
                  <Loading />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Pool
