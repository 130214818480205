import { createStore, applyMiddleware, compose } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'react-router-redux'
import rootReducer from '../reducers'
import rootSaga from '../sagas'
import history from '../history'

import { composeWithDevTools } from 'redux-devtools-extension'

const routeMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware, routeMiddleware]

const middleware = applyMiddleware(...middlewares)

const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(compose(middleware))
)
sagaMiddleware.run(rootSaga)

export { store }
