var env = undefined
var config:any
var config_file = 'stokenet.json'
// var config_file = 'mainnet.json'

try {
  config = require('./config-env/' + config_file)
  config.POOL_TOKENS = [
    {
      label: 'XRD',
      key: 'XRD',
      value: config.XRD_ADDRESS,
      sort: 1,
    },
    {
      label: 'USDT',
      key: 'USDT',
      value: config.USDT_ADDRESS,
      sort: 2,
    },
    {
      label: 'USDC',
      key: 'USDC',
      value: config.USDC_ADDRESS,
      sort: 3,
    },
  ]

  config.DEPOSIT_TOKENS = [
    { label: 'dxXRD', key: 'dxXRD', value: config.DX_XRD_ADDRESS, sort: 1 },
    { label: 'dxUSDT', key: 'dxUSDT', value: config.DX_USDT_ADDRESS, sort: 2 },
    { label: 'dxUSDC', key: 'dxUSDC', value: config.DX_USDC_ADDRESS, sort: 3 },
  ]
} catch(err:any){

}

export default config;